var fileCount = '{{ form.documents|length }}';
var removeButton = "<button type='button' class='btn btn-danger btn-xs' onclick='removeFile($(this));'><i class='fa fa-times' aria-hidden='true'></i></button>";

function removeFile(appli, fileURL)
{
	appli.deleteFile(fileURL);
}

function createAddFile(fileCount)
{
	// grab the prototype template
	var newWidget = $("#filesProto").attr('data-prototype');
	// replace the "__name__" used in the id and name of the prototype
	newWidget = newWidget.replace(/__name__/g, fileCount);

	$("#filesBox").append("<div class='row'>" + "<div class='col-md-1'>" + removeButton + "</div><div class='col-md-10'>" + newWidget + "</div></div>");

	// Once the file is added
	$('#playground_cookiejarbundle_folder_documents_' + fileCount + '_file').on('change', function() {
		// Create another instance of add file button and company
		createAddFile(parseInt(fileCount)+1);
	});
}

$(document).ready(function(){
	createAddFile(fileCount);
	fileCount++;
});