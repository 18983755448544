
// const $ = require('jquery');


// $(document).ready(function(){
//     $('#a3w-js-dl').on('click', function(e) {
//         e.preventDefault();
//         var appli = $(this).val();
//         $.ajax({
//             url:"/admin/update_dl_date/appli",
//             type: "post",
//             dataType: 'json',
//             data: {},
//             success:function(result){
//                 console.log('toto');
//             }
//         });
//     });
// });



// const count = document.getElementById('a3w-js-dl');

// count.onclick( function() {
//     var appli = $(this).val();
//     $.ajax({
//         url:"appli_update_dl_date",
//         type: "post",
//         dataType: 'json',
//         data: {registration: appli},
//         success:function(result){
//             // console.log(result.abc);
//         }
//     });
// })